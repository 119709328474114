<template>
  <DotLottieVue
    ref="animation"
    :class="`lottie-box ${hasParentTriggers ? 'pointer-events-none' : ''}`"
    :style="`width: ${size?.width || size}px; min-width: ${size?.width || size}px; max-width: ${size?.width || size}px; height: ${size?.height || size}px; min-height: ${size?.height || size}px; max-height: ${size?.height || size}px;`"
    :src="src"
    v-bind:[loop]="props.loop"
    v-bind:[autoplay]="props.autoplay"
    :width="size?.width || size" :height="size?.height || size"
    @mouseenter="!hasParentTriggers && playAnimation()" @mouseleave="!hasParentTriggers && reverseAnimation()"
  />
</template>

<script setup>
import { DotLottieVue } from '@lottiefiles/dotlottie-vue'
const animation = ref(null)

const props = defineProps({
  size: { type: [Number, Object], default: 80 },
  src: { type: String },
  loop: { type: Boolean, default: false },
  autoplay: { type: Boolean, default: false },
  reverse: { type: Boolean, default: false },
  hasParentTriggers: { type: Boolean, default: false }
})

function playAnimation(options) {
  let lottie = animation.value.getDotLottieInstance();
  lottie?.setMode('forward');
  lottie?.stop();
  lottie?.setSpeed(options?.speed || 1);
  lottie?.play();
}

function reverseAnimation(options) {
  let lottie = animation.value.getDotLottieInstance();
  if (!props.hasParentTriggers && !props.reverse) return;
  lottie?.setMode('reverse');
  lottie?.setSpeed(options?.speed || 1);
  lottie?.play();
}

function getDotLottieInstance() {
  return animation.value.getDotLottieInstance();
}

onMounted(() => {
})

onBeforeUnmount(() => {
  try {
    animation.value.destroy();
  } catch (e) {
    
  }
})

defineExpose({
  playAnimation,
  reverseAnimation,
  getDotLottieInstance
})
</script>